import {Routes, Route, BrowserRouter as Router} from "react-router-dom";
import EmailTemplateDesign from "./pages/email_template_design";
import Home from "./pages/home/home";
import NewPortfolio from "./pages/home/newPortfolioList";
import Pricing from "./pages/Pricing";
import Screener360_Pricing from "./pages/Screener360_pricing";
import CryptoAlerts from "./pages/CryptoAlerts";
import Welcome from "./pages/home/welcome";
import CryptocurrencyList from "./pages/cryptocurrencylist/cryptoCurrencyList";
import Profile from "./pages/profile/profile";
import Login from "./pages/login/login";

import InfluencerAccount from "./pages/account/influencerAccount";
import PortfolioList from "./pages/account/portfoliolist";
import CryptoInfluencerRankings from "./pages/portfolio/cryptoInfluencerRankings";
import PrivacyPolicy from "./pages/privacypolicy/privacyPolicy";
import TermOfUse from "./pages/termsofuse/termsOfUse";
import About from "./pages/about/about";
import Aboutus from "./pages/about/aboutus";
import Portfolio from "./pages/portfolio/portfolio";
import OrderBook from "./pages/portfolio/orderBook";
import OrderBookShare from "./pages/portfolio/orderBookShare";
import PerformanceSummary from "./pages/portfolio/performanceSummary";
import PerformanceSummaryShare from "./pages/portfolio/performanceSummaryShare";
import CategoryBasedInfluencers from "./pages/portfolio/categoryBasedInfluencers";
import OverallInfluencerRankings from "./pages/portfolio/overallInfluencerRankings";
import PortfolioShare from "./pages/portfolio/PortfolioShare";
import PortfolioFaqs from "./pages/portfolioFaqs/portfolioFaqs";
import Portfolioadd from "./pages/addPage/portfolioadd";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import AirdropForm from "./pages/AirdropFrom";
import Error from "./pages/error/error";
import {useState} from "react";
import Roadmap from "./pages/roadmap";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {PathProvider} from "./includes/PathContext";
import MultiPortfolio from "./pages/portfolio/MultiPortfolio";
import MultiPortfolioWatchlist from "./pages/portfolio/MultiPortfolioWatchlist";
import Recommendations from "./pages/portfolio/recommendations";
import CryptoPage from "./pages/CryptoPage";
import CryptoPagePredictions from "./pages/CryptoPagePredictions";
import CryptoPortfolio from "./pages/home/cryptoPortfolio";
import InfluencerPredictions from "./pages/home/influencerpredictions";
import CryptoForecastForm from "./pages/cryptoForm";
import CategoryPortfolioHome from "./pages/home/categoryPortfolios";
import Whitepaper from "./pages/whitepaper";
import PortfolioNetworthGraph from "./pages/indices/portfolioNetworthGraph";
import NewUserAudit from "./pages/portfolio/new_user_audit";
import {HelmetProvider} from "react-helmet-async";
import MetaProperty from "./common/meta_property/MetaProperty";
import Presale from "./pages/presale";
import RewardTrasactionHistory from "./pages/account/reward_trasaction_history/RewardTransactionHistory";
import QuizPage from "./pages/QuizPage";
import CryptoRecommendation from "./pages/custom_portfolio";
import BestCryptocurrencyList from "./pages/cryptocurrencylist/bestcryptocurrencylist";
import Subscription from "./pages/subscription/Subscription";
import Portfolio_Performance_Tracker from "./pages/portfolio_performance_tracker";
import TopCryptosLayout from "./pages/cryptocurrencylist/TopCryptosLayout.js";
import CategoryCryptosList from "./pages/cryptocurrencylist/categoryCryptosList";
import PredictionsPage from "./pages/PredicitonsPage.js";
import Replacement from "./pages/cryptoReplacement";
import {ReownAppKitProvider} from "./ReownAppKitProvider.tsx";
//import Chatbot from "./pages/chatbot";
import CryptoChart from "./pages/cryptoTrends";
import Account from "./pages/account/profile/Account.jsx";
import CryptoCurrencyWatchlist from "./pages/cryptocurrencylist/cryptocurrencyWatchlist_template.js"
import PortfolioAnalysis from "./pages/portfolio_analysis/PortfolioAnalysis.jsx";

const ASSET_PATH = process.env.PUBLIC_URL;
const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Satoshi, sans-serif",
        },
    },
});

function App() {
    const [isBot, setIsBot] = useState(false);

    const isBotCheck = () => {
        const userAgent = navigator.userAgent.toLowerCase();

        // Check for both Googlebot and Bingbot
        return userAgent.indexOf("googlebot") !== -1 || userAgent.indexOf("bingbot") !== -1;
    };

    useEffect(() => {
        const botStatus = isBotCheck();
        setIsBot(botStatus);
    }, []);

    const [userdata, setUserdata] = useState(null);

    const [checkUserData, setCheckUserData] = useState(false);
    window.constants = {
        asset_path: ASSET_PATH, // Add the asset path to constants
    };
    useEffect(() => {
        //gopal
        // localStorage.setItem(
        //   "cw_portfolio_user",
        //   JSON.stringify({
        //     "user_id":"18","email_id":"gopalgoyal612002@gmail.com","full_name":"gopal goyal","picture":"https://lh3.googleusercontent.com/a/ACg8ocLGVg7GuPWypnJHOmyL6pYyQ7N-IbtaM0JXyxodr-35CN4=s96-c","portfolio_name":"gopal goyal1","country":"India","portfolio_form_redirect":"no","token":"ZWQxaXNVZzJyY3ZUNWljZmZwZHc2UDJoek1ha3RRSlphajFYbkQyOG1xV0toZGYxblZYckpBeFhoQkpXZGp4ODA4b1VZMTRMK25pbW1hWVdlZGdPRFJpWm80MGxwUWlCNmZydzZySVhnZHJ0REpJdUgyeWlQY1pjd3FienoyQUw="
        //        })
        // );

        //sukanta
        // localStorage.setItem(
        //     "cw_portfolio_user",
        //     JSON.stringify({
        //         user_id: "333",
        //         email_id: "jsaniya298@gmail.com",
        //         full_name: "Saniya Jain",
        //         picture:
        //             "https://lh3.googleusercontent.com/a/ACg8ocJiP2ktt8PNxXV7oZIENtulwQ_evwJMWlnfDXwjG8_EnWdlD8a9=s96-c",
        //         portfolio_name: "Saniya Jain 4619",
        //         country: "",
        //         portfolio_form_redirect: "no",
        //         user_role: "guest",
        //         is_verified: "no",
        //         token: "RFJ6cGtGQUtWQVBGZzFRa2JHLzA5QzhsdWFLWGZ5RTNCbzZBOGVYZktJV2FVaTBXSmF4cmdhNmlSWFVlZTQ3cUZSUlg5LzBVSlVGRXJ5OHFFY05DcklaQXlRSWp4NGM5RU9ZckVtL1pwY0JhdUFPN3VyWWQ3bDdYU05MUTlCSmhpc24rcFhlM1N5TnFCb0ZuLzZnTWFxeEt6R2JCV3RZWHhrN1drdEZHVDlBPQ==",
        //     })
        // );
        //tapan
        //                 localStorage.setItem(
        //                       "cw_portfolio_user",
        //                       JSON.stringify({
        //     "user_id": "486",
        //     "email_id": "saniya@crowdwisdom360.com",
        //     "full_name": "Saniya Jain",
        //     "picture": "https://lh3.googleusercontent.com/a/ACg8ocK-a41_qlVWtgDfctbhSq0jD0wyaQSpOfBluLw9NSF51j1Ycg=s96-c",
        //     "portfolio_name": "Saniya Jain 6706",
        //     "country": "",
        //     "portfolio_form_redirect": "no",
        //     "user_role": "guest",
        //     "is_verified": "no",
        //     "token": "RFJ6cGtGQUtWQVBGZzFRa2JHLzA5QzhsdWFLWGZ5RTNCbzZBOGVYZktJV2FVaTBXSmF4cmdhNmlSWFVlZTQ3cUZSUlg5LzBVSlVGRXJ5OHFFY05DcklaQXlRSWp4NGM5RU9ZckVtL1pwY0JhdUFPN3VyWWQ3bDdYU05MUTlCSmhpc24rcFhlM1N5TnFCb0ZuLzZnTWF0Q2ZpN1k2d0hSbXo2M2NzTmtrRC9nPQ=="
        // })
        //                     );

        const userData = localStorage.getItem("cw_portfolio_user");
        setUserdata(userData);
        setCheckUserData(true);
    }, []);

    return (
        <ReownAppKitProvider>
            <ThemeProvider theme={theme}>
                <HelmetProvider>
                    {" "}
                    <MetaProperty />
                    {/* Wrap the entire Router with HelmetProvider */}
                    {!checkUserData && (
                        <>
                            <Box
                                sx={{display: "flex"}}
                                style={{
                                    alignItem: "center",
                                    justifyContent: "center",
                                    marginTop: "300px",
                                }}
                            >
                                <CircularProgress disableShrink />
                            </Box>
                        </>
                    )}
                    {checkUserData && (
                        <>
                            <div className="App">
                                <Router>
                                    <PathProvider>
                                        <Routes>
                                            <Route path="/home" element={<Welcome />} />
                                            <Route path="/" element={<Welcome />} />
                                            <Route path="/roadmap" element={<Roadmap />} />
                                            <Route path="/signUp" element={<Home />} />
                                            <Route path="/crypto/:crypto/quiz" element={<QuizPage />} />
                                            {userdata && (
                                                <>

                                                    <Route path="/pre-sale" element={<Presale />} />
                                                    <Route path="/crypto-screener" element={<CryptocurrencyList />} />
                                                    <Route path="/crypto/:crypto/quiz" element={<QuizPage />} />
                                                    {/* <Route path="/my-portfolio" element={<Portfolio />} /> */}

                                                    {/* <Route path="/portfolio" element={<PortfolioShare />} /> */}
                                                    <Route
                                                        path="/portfolio/:portfolioName"
                                                        element={<PortfolioShare />}
                                                    />
                                                    <Route path="/crypto/:crypto" element={<CryptoPage />} />
                                                    <Route path="/alerts" element={<CryptoAlerts />} />
                                                    <Route
                                                        path="/crypto-screener/replace-your-crypto"
                                                        element={<Replacement />}
                                                    />
                                                    <Route
                                                        path="/custom-portfolio"
                                                        element={<CryptoRecommendation />}
                                                    />
                                                    <Route
                                                        path="/portfolio/:portfolioName/detailed-portfolio-analysis/portfolio-comparison"
                                                        element={<Portfolio_Performance_Tracker />}
                                                    />

                                                    <Route path="/crypto/:crypto/trends" element={<CryptoChart />} />
                                                    <Route
                                                        path="/crypto/:crypto/portfolios"
                                                        element={<CryptoPortfolio />}
                                                    />
                                                    <Route path="/whitepaper" element={<Whitepaper />} />

                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/order-book"
                                                        element={<OrderBook />}
                                                    />

                                                    <Route
                                                        path="/crypto-influencer-rankings"
                                                        element={<CryptoInfluencerRankings />}
                                                    />
                                                    <Route
                                                        path="/crypto-influencer-rankings/predictions"
                                                        element={<OverallInfluencerRankings />}
                                                    />
                                                    <Route path="/indices" element={<PortfolioNetworthGraph />} />

                                                    <Route
                                                        path="/indices/:indexName?"
                                                        element={<PortfolioNetworthGraph />}
                                                    />
                                                    <Route
                                                        path="/category/:categoryName"
                                                        element={<CategoryPortfolioHome />}
                                                    />
                                                    <Route
                                                        path="/category/:categoryName/influencers"
                                                        element={<CategoryBasedInfluencers />}
                                                    />
                                                    <Route path="/subscriptions" element={<Subscription />} />
                                                    <Route
                                                        path="/indices-email-template"
                                                        element={<EmailTemplateDesign />}
                                                    />

                                                    <Route
                                                        path="/subscriptions/screener360"
                                                        element={<Screener360_Pricing />}
                                                    />
                                                    <Route
                                                        path="/portfolio/:portfolioName/order-book"
                                                        element={<OrderBookShare />}
                                                    />

                                                    <Route
                                                        path="/influencer/:influencerName"
                                                        element={<InfluencerAccount />}
                                                    />

                                                    <Route path="/whitepaper" element={<Whitepaper />} />

                                                    <Route
                                                        path="/influencer/:portfolioName/portfolios"
                                                        element={<PortfolioList />}
                                                    />

                                                    <Route
                                                        path="/influencer/:portfolioName/predictions"
                                                        element={<InfluencerPredictions />}
                                                    />

                                                    <Route
                                                        path="/portfolio/:portfolioName/recommendations"
                                                        element={<Recommendations />}
                                                    />

                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/recommendations"
                                                        element={<Recommendations />}
                                                    />

                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis"
                                                        element={<PerformanceSummary />}
                                                    />
                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis/portfolio-comparison"
                                                        element={<Portfolio_Performance_Tracker />}
                                                    />

                                                    <Route path="/predict-now" element={<CryptoForecastForm />} />
                                                    <Route path="/predict-now/login" element={<Login />} />

                                                    <Route path="/airdrop-form" element={<AirdropForm />} />
                                                    <Route path="/airdrop-form/login" element={<Login />} />

                                                    <Route path="/portfolio/:portfolioName/login" element={<Login />} />

                                                    <Route
                                                        path="/portfolio/:portfolioName/detailed-portfolio-analysis"
                                                        element={<PerformanceSummaryShare />}
                                                    />

                                                    <Route path="/account" element={<Account />} />
                                                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                                    <Route path="/termofuse" element={<TermOfUse />} />
                                                    <Route path="/contactus" element={<About />} />
                                                    <Route path="/about-us" element={<Aboutus />} />
                                                    <Route path="/portfolioadd" element={<Portfolioadd />} />
                                                    <Route path="/profile" element={<Profile />} />
                                                    <Route path="/portfoliofaqs" element={<PortfolioFaqs />} />
                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName"
                                                        element={<Portfolio />}
                                                    />

                                                    <Route path="/my-portfolio" element={<MultiPortfolio />} />
                                                    <Route path="/audit-my-portfolio" element={<NewUserAudit />} />

                                                    <Route path="/my-watchlist" element={<MultiPortfolioWatchlist />} />

                                                    <Route path="/portfolio-gallery" element={<Home />} />

                                                    <Route
                                                        path="/portfolio-gallery/portfolios-new"
                                                        element={<NewPortfolio />}
                                                    />

                                                    <Route
                                                        path="/crypto/:crypto/predictions"
                                                        element={<CryptoPagePredictions />}
                                                    />

                                                    <Route path="/crypto-predictions" element={<PredictionsPage />} />
                                                    <Route
                                                        path="/reward-transaction-history"
                                                        element={<RewardTrasactionHistory />}
                                                    />
                                                    <Route
                                                        path="/crypto-screener/hidden-gems"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route
                                                        path="/crypto-screener/breakout-stars"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route
                                                        path="/crypto-screener/smart-money-picks"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route path="/:tagName" element={<BestCryptocurrencyList />} />
                                                    <Route
                                                        path="/crypto-screener/:categoryName"
                                                        element={<CategoryCryptosList />}
                                                    />
                                                    {/* <Route path="/chatbot" element={<Chatbot />} /> */}
                                                    <Route path="/crypto-watchlist" element={<CryptoCurrencyWatchlist />} />
                                                    {/* <Route path="/portfolio-analysis" element={<PortfolioAnalysis />} /> */}
                                                </>
                                            )}
                                            {!userdata && (
                                                <>
                                                    {isBot ? (
                                                        <>
                                                            <Route
                                                                path="/portfolio/:portfolioName"
                                                                element={<PortfolioShare />}
                                                            />
                                                            <Route path="/crypto/:crypto/quiz" element={<QuizPage />} />
                                                            <Route
                                                                path="/crypto-screener"
                                                                element={<CryptocurrencyList />}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Route
                                                                path="/crypto-screener"
                                                                element={<CryptocurrencyList />}
                                                            />
                                                            <Route
                                                                path="/portfolio/:portfolioName"
                                                                element={<PortfolioShare />}
                                                            />
                                                        </>
                                                    )}
                                                    <Route path="/crypto/:crypto/trends" element={<CryptoChart />} />
                                                    {/* <Route path="/chatbot" element={<Chatbot />} /> */}
                                                    <Route path="/crypto-screener" element={<CryptocurrencyList />} />
                                                    <Route path="/my-portfolio" element={<MultiPortfolio />} />
                                                    <Route path="/my-watchlist" element={<Login />} />
                                                    <Route path="/account" element={<Login />} />
                                                    <Route
                                                        path="/custom-portfolio"
                                                        element={<CryptoRecommendation />}
                                                    />
                                                    <Route
                                                        path="/portfolio/:portfolioName/detailed-portfolio-analysis/portfolio-comparison"
                                                        element={<Portfolio_Performance_Tracker />}
                                                    />
                                                    <Route
                                                        path="/category/:categoryName"
                                                        element={<CategoryPortfolioHome />}
                                                    />
                                                    <Route path="/whitepaper" element={<Whitepaper />} />
                                                    <Route
                                                        path="/indices-email-template"
                                                        element={<EmailTemplateDesign />}
                                                    />
                                                    <Route
                                                        path="/portfolio/:portfolioName"
                                                        element={<PortfolioShare />}
                                                    />
                                                    <Route path="/indices" element={<PortfolioNetworthGraph />} />

                                                    <Route
                                                        path="/indices/:indexName?"
                                                        element={<PortfolioNetworthGraph />}
                                                    />
                                                    <Route path="/subscriptions" element={<Subscription />} />
                                                    <Route path="/audit-my-portfolio" element={<NewUserAudit />} />
                                                    <Route
                                                        path="/subscriptions/screener360"
                                                        element={<Screener360_Pricing />}
                                                    />
                                                    <Route
                                                        path="/category/:categoryName/influencers"
                                                        element={<CategoryBasedInfluencers />}
                                                    />
                                                    <Route path="/crypto/:crypto" element={<CryptoPage />} />
                                                    <Route path="/alerts" element={<CryptoAlerts />} />
                                                    <Route
                                                        path="/crypto-screener/replace-your-crypto"
                                                        element={<Replacement />}
                                                    />
                                                    <Route
                                                        path="/crypto/:crypto/predictions"
                                                        element={<CryptoPagePredictions />}
                                                    />
                                                    <Route path="/crypto-predictions" element={<PredictionsPage />} />
                                                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                                    <Route path="/termofuse" element={<TermOfUse />} />
                                                    <Route path="/contactus" element={<About />} />
                                                    <Route path="/about-us" element={<Aboutus />} />
                                                    <Route path="/portfolioadd" element={<Login />} />
                                                    <Route path="/profile" element={<Login />} />
                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/order-book"
                                                        element={<Login />}
                                                    />
                                                    <Route path="/whitepaper" element={<Whitepaper />} />

                                                    <Route
                                                        path="/portfolio/:portfolioName/recommendations"
                                                        element={<Recommendations />}
                                                    />

                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/recommendations"
                                                        element={<Login />}
                                                    />

                                                    <Route
                                                        path="/crypto-influencer-rankings"
                                                        element={<CryptoInfluencerRankings />}
                                                    />
                                                    <Route
                                                        path="/crypto-influencer-rankings/predictions"
                                                        element={<OverallInfluencerRankings />}
                                                    />

                                                    <Route
                                                        path="/portfolio/:portfolioName/order-book"
                                                        element={<OrderBookShare />}
                                                    />
                                                    <Route
                                                        path="/influencer/:influencerName"
                                                        element={<InfluencerAccount />}
                                                    />

                                                    <Route path="/predict-now" element={<CryptoForecastForm />} />
                                                    <Route path="/predict-now/login" element={<Login />} />

                                                    <Route path="/airdrop-form" element={<AirdropForm />} />
                                                    <Route path="/airdrop-form/login" element={<Login />} />
                                                    <Route path="/portfolio/:portfolioName/login" element={<Login />} />

                                                    <Route
                                                        path="/influencer/:portfolioName/portfolios"
                                                        element={<PortfolioList />}
                                                    />
                                                    <Route
                                                        path="/influencer/:portfolioName/predictions"
                                                        element={<InfluencerPredictions />}
                                                    />

                                                    <Route
                                                        path="/crypto/:crypto/portfolios"
                                                        element={<CryptoPortfolio />}
                                                    />
                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis"
                                                        element={<Login />}
                                                    />
                                                    <Route
                                                        path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis/portfolio-comparison"
                                                        element={<Portfolio_Performance_Tracker />}
                                                    />

                                                    <Route
                                                        path="/portfolio/:portfolioName/detailed-portfolio-analysis"
                                                        element={<PerformanceSummaryShare />}
                                                    />
                                                    <Route path="/portfoliofaqs" element={<PortfolioFaqs />} />
                                                    <Route path="/portfolio-gallery" element={<Home />} />
                                                    <Route
                                                        path="/portfolio-gallery/portfolios-new"
                                                        element={<NewPortfolio />}
                                                    />
                                                    <Route path="/pre-sale" element={<Presale />} />
                                                    <Route
                                                        path="/crypto-screener/hidden-gems"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route
                                                        path="/crypto-screener/breakout-stars"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route
                                                        path="/crypto-screener/smart-money-picks"
                                                        element={<TopCryptosLayout />}
                                                    />
                                                    <Route path="/:tagName" element={<BestCryptocurrencyList />} />
                                                    <Route
                                                        path="/crypto-screener/:categoryName"
                                                        element={<CategoryCryptosList />}
                                                    />
                                                    <Route path="/crypto-watchlist" element={<CryptoCurrencyWatchlist />} />
                                                    {/* <Route path="/portfolio-analysis" element={<PortfolioAnalysis />} /> */}
                                                </>
                                            )}

                                            <Route path="/login" element={<Home showPopup={true} />} />
                                            <Route path="*" element={<Error />} />
                                        </Routes>
                                    </PathProvider>
                                </Router>
                            </div>
                        </>
                    )}
                </HelmetProvider>
            </ThemeProvider>
        </ReownAppKitProvider>
    );
}

export default App;
