import React from "react";
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartHook = ({ headingText = "", style = {} }) => {
    const data = {
        labels: ["HT", "TUT", "Sarosa", "ETH", "BTC", "DOGE", "XRP", "SOL", "LTC", "ADA"],
        datasets: [
            {
                label: "Dataset",
                data: [10, 15, 20, 10, 8, 12, 6, 14, 9, 16],
                backgroundColor: [
                    "rgb(244, 148, 27)",
                    "rgb(244, 187, 44)",
                    "rgb(61, 190, 255)",
                    "rgb(43, 45, 45)",
                    "rgb(76, 175, 80)",
                    "rgb(156, 39, 176)",
                    "rgb(33, 150, 243)",
                    "rgb(255, 87, 34)",
                    "rgb(121, 85, 72)",
                    "rgb(0, 188, 212)",
                ],
                borderColor: "#fff",
                borderWidth: 2,
            },
        ],
    };

    const options = {
        cutout: "60%",
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: "#333",
                    font: {
                        size: 14,
                        weight: "bold",
                    },
                    padding: 20, // ⬅️ increased from 12 to 20
                    boxWidth: 18,
                },
            },
            tooltip: {
                bodyFont: {
                    size: 14,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div>
            <p className="piechart-text">{headingText}</p>
            <div style={style} className="piechart">
                <Doughnut data={data} options={options} />
            </div>
        </div>
    );
};

export default PieChartHook;
